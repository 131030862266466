import Vue from "vue";
import Vuex, { StoreOptions } from "vuex";
import { RootState } from "./types";
import { user } from './modules/user/index'
import { system } from './modules/system/index'
import { tags } from './modules/tags/index'

import persistedState from "vuex-persistedstate";
Vue.use(Vuex);

const store: StoreOptions<RootState> = {
  modules: {
    user,
    system,
    tags
  },
  plugins: [
    persistedState({ // localStorage持久化处理
      reducer(cache: any) {
        return {
          system: cache.system,
          tags: cache.tags,
          company: cache.company
        }
      }
    }),
    persistedState({ // sessionStorage持久化处理
      storage: window.sessionStorage,
      reducer(cache: any) {
        return {
          user: cache.user
        }
      }
    })
  ],
};
export default new Vuex.Store<RootState>(store);
