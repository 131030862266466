














import { Component, Vue } from 'vue-property-decorator'
import TagsView from '../components/TagsView/index.vue'
import store from "@/store";
import Bus from '@/utils/bus'

@Component({
  name: 'AppMain',
  components: {
    'tag-views': TagsView
  }
})
export default class AppMain extends Vue {

  visitedViews = store.state.tags.visitedViews
  get cachedViews() {
    return store.state.tags.cachedViews
  }

  get key() {
    return this.$route.path
  }


  // 获取有keep-alive子节点的Vnode
  getVnode() {
    console.log('this.$children:', this.$children);
    // 判断子集非空
    if (this.$children.length == 0) return false;
    let vnode;
    for (let item of this.$children) {
      // 如果data中有key则代表找到了keep-alive下面的子集，这个key就是router-view上的key
      //@ts-ignore
      if (item.$vnode.data.key) {
        vnode = item.$vnode;
        break;
      }
    }
    return vnode ? vnode : false;
  }

  // 移除keep-alive缓存
  removeCache(name: any, view = {}) {
    console.log('name', name);
    let vnode: any = this.getVnode();
    if (!vnode) return false;
    let componentInstance = vnode.parent.componentInstance;
    // 这个key是用来获取前缀用来后面正则匹配用的
    let keyStart = vnode.key.split('/')[0];
    //@ts-ignore
    let thisKey = `${keyStart}${view.fullPath}`;
    //@ts-ignore
    let regKey = `${keyStart}${view.path}`;
    //@ts-ignore
    this[name]({ componentInstance, thisKey, regKey });
  }

  // 移除其他
  closeOthersTags({ componentInstance, thisKey }: any) {
    Object.keys(componentInstance.cache).forEach((key, index) => {
      if (key != thisKey) {
        // 1 销毁实例(这里存在多个key指向一个缓存的情况可能前面一个已经清除掉了所有要加判断)
        if (componentInstance.cache[key]) {
          componentInstance.cache[key].componentInstance.$destroy();
        }
        // 2 删除缓存
        delete componentInstance.cache[key];
        // 3 移除key中对应的key
        componentInstance.keys.splice(index, 1);
      }
    });
  }

  // 移除单个缓存
  closeSelectedTag({ componentInstance, regKey }: any) {
    let reg = new RegExp(`^${regKey}`);
    Object.keys(componentInstance.cache).forEach((key, i) => {
      if (reg.test(key)) {
        // 1 销毁实例
        if (componentInstance.cache[key]) {
          componentInstance.cache[key].componentInstance.$destroy();
        }
        // 2 删除缓存
        delete componentInstance.cache[key];
        // 3 移除key中对应的key
        componentInstance.keys.splice(i, 1);
      }
    });
  }

  mounted() {
    // 关闭标签触发
    Bus.$on('removeCache', (name: any, view: any) => {
      this.removeCache(name, view);

    });
  }

}
