
















import { Component, Vue } from "vue-property-decorator";
import { AppMain, Sidebar, Header } from "./components";
import api from "@/api";
import store from "@/store";
@Component({
  name: "Layout",
  components: {
    Header,
    AppMain,
    Sidebar,
  },
})
export default class Layout extends Vue {


}
