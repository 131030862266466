import { Module } from "vuex";
import { SystemState } from "./types";
import { RootState } from "../../types";
import { getters } from "./getters";
import { actions } from "./actions";
import { mutations } from "./mutations";

const state: SystemState = {
  column: [],
  menu: [],
  config: {}
};
const namespaced = true;
export const system: Module<SystemState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
export default state;
