import { Module } from "vuex";
import { ITagsViewState } from "./types";
import { RootState } from "../../types";
import { getters } from "./getters";
import { actions } from "./actions";
import { mutations } from "./mutations";

const state: ITagsViewState = {
  visitedViews: [],
  cachedViews: [],
  name: ''
}
const namespaced = true;
export const tags: Module<ITagsViewState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
export default state;
