import { SystemState, Item } from './types'
import { ActionTree } from 'vuex'
import { RootState } from '../../types'

export const actions: ActionTree<SystemState, RootState> = {
    addColumn({ commit }, column: Item): void {
        commit("saveFilter", column)
    },
    addMenu({ commit }, menu: Item): void {
        commit("saveMenu", menu)
    },
    addConfig({ commit }, config: string): void {
        commit("saveConfig", config)
    }
    
}
