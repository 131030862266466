





















import { Component, Vue, Prop } from "vue-property-decorator";
import api from "@/api";
import store from "@/store";

@Component
export default class Header extends Vue {
   @Prop() private count!: 0
  state = store.state;
  value = store.state.user.sysIndex;
  exitLogin() {
    this.$confirm("你确定要退出系统吗?", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    }).then(() => {
      api.destroyToken().then(() => {
        store.dispatch("user/delLogin");
        this.$message.success("操作成功");
        this.$router.push({path: '/login'});
      });
    });
  }

  sysIndex = store.state.user.sysIndex;

  mounted() {
    this.TimeSys();
  }

  TimeSys() {
    setTimeout(() => {
      if (this.sysIndex != JSON.parse(sessionStorage.getItem('vuex') as any).user.sysIndex) {
        location.href = location.origin;
      } else {
        this.TimeSys();
      }
    }, 10000);
  }

  //切换平台
  tab(key: any) {
    store.dispatch("user/changeSys", key);
    store.dispatch("tags/delAllViews");
    // window.location =
    const { href } = this.$router.resolve({
      path: "/home",
    });
    window.location.href = href;
    window.location.reload();
  }

  get user() {
    return store.state.user.user;
  }
}
