import { ITagsViewState, ITagView } from './types'
import { ActionTree } from 'vuex'
import { RootState } from '../../types'

export const actions: ActionTree<ITagsViewState, RootState> = {
  addView({ commit }, item: ITagView) {
    commit('ADD_VISITED_VIEW', {
      fullPath: item.fullPath,
      hash: item.hash,
      meta: { ...item.meta },
      name: item.name,
      params: { ...item.params },
      path: item.path,
      query: { ...item.query },
      title: item.title
    })
    commit('ADD_CACHED_VIEW', item)
  },
  addVisitedView({ commit }, view: ITagView) {
    commit('ADD_VISITED_VIEW', view)
  },
  delView({ commit }, view: ITagView) {
    commit('DEL_VISITED_VIEW', view)
    commit('DEL_CACHED_VIEW', view)
  },
  delCachedView({ commit }, view: ITagView) {
    commit('DEL_CACHED_VIEW', view)
  },
  delOthersViews({ commit }, view: ITagView) {
    commit('DEL_OTHERS_VISITED_VIEWS', view)
    commit('DEL_OTHERS_CACHED_VIEWS', view)
  },
  delAllViews({ commit }) {
    commit('DEL_ALL_VISITED_VIEWS')
    commit('DEL_ALL_CACHED_VIEWS')
  },
  delAllCachedViews({ commit }) {
    commit('DEL_ALL_CACHED_VIEWS')
  },
  updateVisitedView({ commit }, view: ITagView) {
    commit('UPDATE_VISITED_VIEW', view)
  },

  NameView({ commit }, name: string) {
    commit('DELETE_NAME_VIEW', name)
  },
}
