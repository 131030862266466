import { UserState } from './types'
import { ActionTree } from 'vuex'
import { RootState } from '../../types'

export const actions: ActionTree<UserState, RootState> = {
  Login({ commit }, userInfo: UserState): void {
    commit('saveUserInfo', userInfo)
  },
  delLogin({ commit }): void {
    commit('deleteUserInfo')
  },
  changeSys({ commit }, sysIndex: number): void {
    commit('changeSys', sysIndex)
  }
}
