// 权限管理路由
const integral: any = [
    {
        path: 'coupon/couponList',
        name: 'applet_coupon',
        meta: {
            title: '优惠券列表'
        },
        component: () => import('@/views/coupon/couponList.vue')
    },
    {
        path: 'coupon/couponListZH',
        name: 'couponListZH',
        meta: {
            title: '已召回优惠券列表',
            pathTitle: 'applet_coupon'
        },
        component: () => import('@/views/coupon/couponListZH.vue')
    },
    {
        path: 'coupon/createCoupon',
        name: 'applet_coupon_create',
        meta: {
            title: '创建优惠券',
            pathTitle: 'applet_coupon'
        },
        component: () => import('@/views/coupon/createCoupon.vue')
    },
    {
        path: 'coupon/couponDetail',
        name: 'applet_coupon_create2',
        meta: {
            title: '优惠券详情'
        },
        component: () => import('@/views/coupon/couponDetail.vue')
    },
    {
        path: 'coupon/couponAgentD',
        name: 'applet_coupon_create3',
        meta: {
            title: '优惠券代理列表',
            pathTitle: 'applet_integral_apply'
        },
        component: () => import('@/views/coupon/couponAgentD.vue')
    },
    {
        path: 'coupon/couponUse',
        name: 'coupon_receive',
        meta: {
            title: '优惠券领用列表'
        },
        component: () => import('@/views/coupon/couponUse.vue')
    },
]
export default integral
