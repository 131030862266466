const general: any = [
    {
        path: 'order/realtimeOrder',
        name: 'realtimeOrder',
        meta: {
            pathTitle: 'applet_channel_api',
            title: '订单列表'
        },
        component: () => import('@/views/order/realtimeOrder.vue')
    },
    {
        path: 'order/orderDetails',
        name: 'orderDetails',
        meta: {
            pathTitle: 'cooperation_client_open',
            title: '订单明细'
        },
        component: () => import('@/views/order/orderDetails.vue')
    }
]
export default general