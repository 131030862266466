// 权限管理路由
const general: any = [
    {
        path: 'account/list',
        name: 'channel_manage_user',
        meta: {
            pathTitle: 'channel_manage',
            title: '账号列表'
        },
        component: () => import('@/views/account/list.vue')
    }
]
export default general