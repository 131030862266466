import { ITagView } from '@/store/modules/tags/types';
import dayjs from 'dayjs'
import store from "@/store";

/*
 * 时间戳（日期） 格式化
 */
const timeFormat = (value: string | number, format = "YYYY-MM-DD HH:mm:ss") => {
    if (value) {
        if (value.toString().length === 10) {
            value = parseFloat(value.toString()) * 1000
        }
        return dayjs(value).format(format)
    } else {
        return '-'
    }
}
/*
 * 银行账号格式化
 */
const bankNoFormat = (value: string) => {
    if (!value) {
        return '-'
    }
    value = value.replace(/(\d{4})(?=\d)/g, '$1 ')
    return value
}

/*
 * 金额格式化
 */
const moneyFormat = (value: string) => {
    if (!value) {
        return '-'
    }
    return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

/*
 * 获取tabs菜单名称
 */
const getMenuName = (route: ITagView) => {
    if (route.meta.title) {
        return route.meta.title;
    }
    let name = "未命名"
    store.state.system.menu.forEach(item => {
        if (item.value === route.name) {
            name = item.name
        }
    })
    return name
}

export default {
    timeFormat,
    moneyFormat,
    bankNoFormat,
    getMenuName
}