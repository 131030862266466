import axios from 'axios'
import { Message, MessageBox } from 'element-ui'
import store from '@/store'
import Router from '@/router'

const http = axios.create({
    baseURL: '/api'
})
// 增加头部信息
http.interceptors.request.use(
    (config: any) => {
        // 检查是否有凭据
        const token: any = store.state.user.token
        // config.headers['Content-Type'] = 'application/json'

        // 获取凭据带给接口
        if (token) {
            config.headers.token = encodeURIComponent(token)
        }
        return config
    },
    error => {
        Promise.reject(error)
    }
)
http.interceptors.response.use(
    response => {
        const dataAxios = response.data
        console.log('222', response);
        if (dataAxios.type === 'application/octet-stream') {
            return dataAxios
        }
        // @ts-ignore
        if(response.config.url.indexOf('J27532') >-1) {
            return dataAxios
        }
        const { errcode } = dataAxios
        switch (errcode) {
            case 0:
                return dataAxios.data
            case 2160:
                MessageBox.alert(dataAxios.msg, '提示', { type: 'info', showClose: false }).then(() => {
                    // location.reload()

                })
                return false
            case 10001:
            case 10002:
            case 10003:
            case 10004: {
                store.dispatch('user/delLogin')
                let msg = "当前登录状态已失效";
                if (errcode === 10002) {
                    if (response.config.data && JSON.parse(response.config.data).ret) {
                        msg = "权限已变更，请重新登录";
                    }
                }
                MessageBox.alert(msg, '提示', { type: 'info', showClose: false }).then(() => {
                    // location.reload()
                    Router.replace('/login')
                })
                return false
            }
            case 10005:
                Message.error("未找到请求的资源")
                return Promise.reject({
                    message: "未找到请求的资源"
                })
            case 10006:
                if (response.config.url?.indexOf("A1009") === -1) {
                    Message.error("没有权限")
                }
                return Promise.reject({
                    message: "没有权限"
                })
            default:
            case 10000:
                Message.error(dataAxios.msg)
                return Promise.reject({
                    message: dataAxios.msg,
                    data: dataAxios.data
                })
        }
    },
    error => {
        if (error && error.response) {
            switch (error.response.status) {
                case 400: error.message = '请求错误'; break
                case 401: error.message = '未授权，请登录'; break
                case 403: error.message = '拒绝访问'; break
                case 404: error.message = '请求地址出错'; break
                case 408: error.message = '请求超时'; break
                case 500: error.message = '服务器内部错误'; break
                case 501: error.message = '服务未实现'; break
                case 502: error.message = '网关错误'; break
                case 503: error.message = '服务不可用'; break
                case 504: error.message = '网关超时'; break
                case 505: error.message = 'HTTP版本不受支持'; break
                default: break
            }
        }
        Message.error(error.message)
        return Promise.reject(error)
    }
)
export default http
