import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import "@/styles/index.scss";
import filters from '@/utils/filters'
import './components'
import 'element-ui/lib/theme-chalk/icon.css'

// @ts-ignore
import Print from 'vue-print-nb'

Vue.use(Print);

Vue.use(ElementUI);
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
Object.keys(filters).forEach((key) => Vue.filter(key, filters[key]));

Vue.config.productionTip = false;

router.beforeEach((to: any, from, next) => {
  // if (!to.meta.nologin) {
  //   if (!store.state.user.token) {
  //     next({
  //       path: '/login',
  //       query: {
  //         redirect: to.fullPath
  //       }
  //     })
  //     return
  //   }
  // }
  next()
})

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
