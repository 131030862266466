import { MutationTree } from "vuex";
import { ITagView, ITagsViewState } from "./types";

export const mutations: MutationTree<ITagsViewState> = {
    DELETE_NAME_VIEW(state, name: string) {
        if (!state.name) {
            state.name = name
            return
        }
        // 如果不是上次登录的账号则删除TAB
        if (state.name !== name) {
            state.visitedViews = []
            state.cachedViews = []
            state.name = name
            return
        }

    },
    ADD_VISITED_VIEW(state, view: ITagView) {
        if (state.visitedViews.some(v => v.path === view.path)) return
        state.visitedViews.push(
            Object.assign({}, view, {
                title: view.meta.title || 'no-name'
            })
        )
    },
    ADD_CACHED_VIEW(state, view: ITagView) {
        if (view.name === null) return
        if (state.cachedViews.includes(view.name)) return
        if (!view.meta.noCache) {
            state.cachedViews.push(view.name)
        }
    },
    DEL_VISITED_VIEW(state, view: ITagView) {
        for (const [i, v] of state.visitedViews.entries()) {
            if (v.path === view.path) {
                state.visitedViews.splice(i, 1)
                break
            }
        }
    },
    DEL_CACHED_VIEW(state, view: ITagView) {
        if (view.name === null) return
        const index = state.cachedViews.indexOf(view.name)
        index > -1 && state.cachedViews.splice(index, 1)
    },
    DEL_OTHERS_VISITED_VIEWS(state, view: ITagView) {
        state.visitedViews = state.visitedViews.filter(v => {
            return v.meta.affix || v.path === view.path
        })
    },
    DEL_OTHERS_CACHED_VIEWS(state, view: ITagView) {
        if (view.name === null) return
        const index = state.cachedViews.indexOf(view.name)
        if (index > -1) {
            state.cachedViews = state.cachedViews.slice(index, index + 1)
        } else {
            // if index = -1, there is no cached tags
            state.cachedViews = []
        }
    },
    DEL_ALL_VISITED_VIEWS(state) {
        // keep affix tags
        const affixTags = state.visitedViews.filter(tag => tag.meta.affix)
        state.visitedViews = affixTags
    },
    DEL_ALL_CACHED_VIEWS(state) {
        state.cachedViews = []
    },
    UPDATE_VISITED_VIEW(state, view: ITagView) {
        for (let v of state.visitedViews) {
            if (v.fullPath === view.fullPath) {
                v = Object.assign(v, view)
                break
            }
        }
    }
};
