

































import { Component, Vue } from "vue-property-decorator";
import api from "@/api";
import store from "@/store";
import {constantRoutes} from '@/router'

@Component({
    name: "SideBar",
    components: {},
})
export default class SideBar extends Vue {
    menu = [];
    getMeun() {
        api.menus().then((res) => {
            res.forEach((sys: any) => {
                if (sys.code === 'applet') {
                    sys.list.forEach((item: any) => {
                        store.dispatch("system/addMenu", {
                            value: item.code,
                            name: item.name,
                        });
                        item.list.forEach((row: any) => {
                            (constantRoutes[3].children as any).forEach((itx: any) => {
                                if(row.code == itx.name) {
                                    store.dispatch("system/addMenu", {
                                        value: row.code,
                                        name: row.name
                                    });
                                    row.path = itx.path
                                }
                            })

                        });
                    });
                    this.menu = sys.list;
                }
            });
        });
    }

    meta = {}
    route = {}
    get activeMenu() {
        this.route = this.$route;
        const { meta, path } =  this.route as any;
        this.meta = meta
        if(this.$route.path == '/order/orderDetail') {
            return '/order'
        }
        return path;
    }

    // 根据code 查找路由进行跳转
    ckUrl(code: string) {
        this.$router.push({ name: code });
    }

    created() {
        this.getMeun();
    }
}
