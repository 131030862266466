// 权限管理路由
const task: any = [
    {
        path: 'activity/task',
        name: 'apple_task_api',
        meta: {
            title: '任务列表'
        },
        component: () => import('@/views/activity/task.vue')
    },
    {
        path: 'activity/taskEdit',
        name: 'taskEdit',
        meta: {
            pathTitle: 'task_activity',
            title: '新建任务'
        },
        component: () => import('@/views/activity/taskEdit.vue')
    },
    {
        path: 'activity/task',
        name: 'task_activity',
        meta: {
            title: '任务列表'
        },
        component: () => import('@/views/activity/task.vue')
    },
    {
        path: 'activity/shareList',
        name: 'shareList_activity',
        meta: {
            title: '分享有礼列表'
        },
        component: () => import('@/views/activity/shareList.vue')
    },
    {
        path: 'activity/consumptionList',
        name: 'consumptionList_activity',
        meta: {
            title: '累计消费列表'
        },
        component: () => import('@/views/activity/consumptionList.vue')
    },
    {
        path: 'activity/pointList',
        name: 'pointList_activity',
        meta: {
            title: '累计积分列表'
        },
        component: () => import('@/views/activity/pointList.vue')
    },
    {
        path: 'activity/consumptionOrder',
        name: 'consumptionOrder_activity',
        meta: {
            title: '订单'
        },
        component: () => import('@/views/activity/consumptionOrder.vue')
    },
    {
        path: 'activity/pointOrder',
        name: 'pointOrder_activity',
        meta: {
            title: '累计积分订单'
        },
        component: () => import('@/views/activity/pointOrder.vue')
    }
]
export default task
