import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import member from './member'
import marketing from './marketing'
import integral from './integral'
import activity from './activity'
import tinymce from './tinymce'
import Layout from "@/views/layout/index.vue";
import coupon from "./coupon";
import agent from "./agent";
import business from "./business";
import channel from "./channel";
import order from "./order";
import account from "./account";
import file from "./file";
import company from "./company";
import task from "./task";


// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location: any) {
  return (originalPush.call(this, location) as any).catch((err: any) => err)
}

Vue.use(VueRouter);

export const constantRoutes: RouteConfig[] = [
  {
    path: '/redirect/:path(.*)',
    component: () => import("@/views/redirect/index.vue"),
  },
  {
    path: '/login',
    component: () => import('@/views/login_yingxiao.vue'),
    meta: {
      title: '登录',
      nologin: true
    }
  },
  {
    path: '/',
    component: () => import('@/views/login_yingxiao.vue'),
    meta: {
      title: '登录',
      nologin: true
    }
  },
  {
    path: '/',
    component: Layout,
    meta: {
      title: '首页'
    },
    children: [
      ...marketing, ...member, ...integral, ...activity, ...tinymce, ...coupon, ...agent, ...business, ...channel, ...order,...account, ...file, ...company, ...task,
      {
        path: 'home',
        name: 'home',
        component: () => import('@/views/Home.vue'),
        meta: {
          title: '首页'
        }
      }
    ]
  }
]


const router = new VueRouter({
  routes: constantRoutes
});


export default router;
