






















// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import path from "path";
import { Component, Vue, Watch } from "vue-property-decorator";
import { RouteConfig } from "vue-router";
import ScrollPane from "./ScrollPane.vue";
import store from "@/store";
import { ITagView } from "@/store/modules/tags/types";
import Bus from '@/utils/bus'

@Component({
  name: "TagsView",
  components: {
    ScrollPane,
  },
})
export default class extends Vue {
  private visible = false;
  private top = 0;
  private left = 0;
  private selectedTag: ITagView = {};
  private affixTags: ITagView[] = [];

  get visitedViews() {
    return store.state.tags.visitedViews;
  }

  @Watch("$route")
  private onRouteChange() {
    this.addTags();
    this.moveToCurrentTag();
  }

  @Watch("visible")
  private onVisibleChange(value: boolean) {
    if (value) {
      document.body.addEventListener("click", this.closeMenu);
    } else {
      document.body.removeEventListener("click", this.closeMenu);
    }
  }

  routes = [];

  private isActive(route: ITagView) {
    return route.path === this.$route.path;
  }

  private isAffix(tag: ITagView) {
    return tag.meta && tag.meta.affix;
  }

  private filterAffixTags(routes: RouteConfig[], basePath = "/") {
    let tags: ITagView[] = [];
    routes.forEach((route) => {
      if (route.meta && route.meta.affix) {
        const tagPath = path.resolve(basePath, route.path);
        tags.push({
          fullPath: tagPath,
          path: tagPath,
          name: route.name,
          meta: { ...route.meta },
        });
      }
      if (route.children) {
        const childTags = this.filterAffixTags(route.children, route.path);
        if (childTags.length >= 1) {
          tags = [...tags, ...childTags];
        }
      }
    });
    return tags;
  }

  private addTags() {
    const { name } = this.$route;
    if (name) {
      store.dispatch("tags/addView", this.$route);
    }
    return false;
  }

  private moveToCurrentTag() {
    const tags = (this.$refs.tag as any[]) || []; // TODO: better typescript support for router-link
    this.$nextTick(() => {
      for (const tag of tags) {
        if ((tag.to as ITagView).path === this.$route.path) {
          (this.$refs.scrollPane as ScrollPane).moveToTarget(tag as any);
          // When query is different then update
          console.log('996', (tag.to as ITagView).fullPath, this.$route.fullPath)
          console.log('998', this.$route)
          if ((tag.to as ITagView).fullPath !== this.$route.fullPath) {
            store.dispatch("tags/updateVisitedView", this.$route);
          }
          break;
        }
      }
    });
  }

  private refreshSelectedTag(view: ITagView) {
    store.dispatch("tags/delCachedView", view);
    const { fullPath } = view;
    this.$nextTick(() => {
      this.$router
        .replace({
          path: "/redirect" + fullPath,
        })
        .catch((err) => {
          console.warn(err);
        });
    });
  }

  private closeSelectedTag(view: ITagView) {
    store.dispatch("tags/delView", view);
    if (this.isActive(view)) {
      this.toLastView(store.state.tags.visitedViews, view);
    }
    //关闭单个
    Bus.$emit('removeCache','closeSelectedTag',view)
  }

  private closeOthersTags() {
    if (
      this.selectedTag.fullPath !== this.$route.path &&
      this.selectedTag.fullPath !== undefined
    ) {
      this.$router.push(this.selectedTag.fullPath).catch((err) => {
        console.warn(err);
      });
    }
    store.dispatch("tags/delOthersViews", this.selectedTag);
    this.moveToCurrentTag();
    //关闭其他
    Bus.$emit('removeCache','closeOthersTags',this.selectedTag);
  }

  private closeAllTags(view: ITagView) {
    store.dispatch("tags/delAllViews");
    if (this.affixTags.some((tag) => tag.path === this.$route.path)) {
      return;
    }
    this.toLastView(store.state.tags.visitedViews, view);
  }

  private toLastView(visitedViews: ITagView[], view: ITagView) {
    const latestView = visitedViews.slice(-1)[0];
    if (latestView !== undefined && latestView.fullPath !== undefined) {
      this.$router.push(latestView.fullPath).catch((err) => {
        console.warn(err);
      });
    } else {
      // Default redirect to the home page if there is no tags-view, adjust it if you want
      if (view.name === "Dashboard") {
        // to reload home page
        this.$router
          .replace({ path: "/redirect" + view.fullPath })
          .catch((err) => {
            console.warn(err);
          });
      } else {
        this.$router.push("/").catch((err) => {
          console.warn(err);
        });
      }
    }
  }

  private openMenu(tag: ITagView, e: MouseEvent) {
    const menuMinWidth = 105;
    const offsetLeft = this.$el.getBoundingClientRect().left; // container margin left
    const offsetWidth = (this.$el as HTMLElement).offsetWidth; // container width
    const maxLeft = offsetWidth - menuMinWidth; // left boundary
    const left = e.clientX - offsetLeft + 15; // 15: margin right
    if (left > maxLeft) {
      this.left = maxLeft;
    } else {
      this.left = left;
    }

    const top = e.clientY - this.$el.getBoundingClientRect().top + 15; // 15: margin right
    this.top = top;
    this.visible = true;
    this.selectedTag = tag;
  }

  private closeMenu() {
    this.visible = false;
  }

  private handleScroll() {
    this.closeMenu();
  }
}
