const general: any = [
    {
        path: 'company/list',
        name: 'apple_qiye_api',
        meta: {
            title: '企业管理'
        },
        component: () => import('@/views/company/list.vue')
    },
    {
        path: 'company/tinymce',
        name: 'companyTinymce',
        meta: {
            pathTitle: 'apple_qiye_api',
            title: '编辑企业信息'
        },
        component: () => import('@/views/company/tinymce.vue')
    },
    {
        path: 'company/staff',
        name: 'staff',
        meta: {
            pathTitle: 'apple_qiye_api',
            title: '企业员工'
        },
        component: () => import('@/views/company/staff.vue')
    }
]
export default general
