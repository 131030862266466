// 权限管理路由
const integral: any = [
    {
        path: 'agent/agentList',
        name: 'applet_broker',
        meta: {
            title: '经纪人列表'
        },
        component: () => import('@/views/agent/agentList.vue')
    },
    {
        path: 'agent/agentDetail',
        name: 'agentDetail',
        meta: {
            title: '经纪人详情',
            pathTitle: 'applet_broker'
        },
        component: () => import('@/views/agent/agentDetail.vue')
    },
    {
        path: 'agent/agentEquity',
        name: 'applet_broker_equity',
        meta: {
            title: '经纪人权益',
        },
        component: () => import('@/views/agent/agentEquity.vue')
    },
    {
        path: 'agent/agentApproval',
        name: 'applet_broker_check',
        meta: {
            title: '经纪人审批'
        },
        component: () => import('@/views/agent/agentApproval.vue')
    },
]
export default integral
