// 权限管理路由
const general: any = [
    {
        path: 'business/detail',
        name: 'businessDetail',
        meta: {
            pathTitle: 'channel_manage_order',
            title: '核算明细'
        },
        component: () => import('@/views/business/detail.vue')
    },
    {
        path: 'business/list',
        name: 'applet_write_api',
        meta: {
            pathTitle: 'businessList'
        },
        component: () => import('@/views/business/list.vue')
    },
    {
        path: 'business/orderBusiness',
        name: 'orderBusiness',
        meta: {
            pathTitle: 'channel_manage_order',
            title: '核算订单'
        },
        component: () => import('@/views/business/orderBusiness.vue')
    }
]
export default general