// 权限管理路由
const marketing: any = [
    {
        path: 'marketing/marketingList',
        name: 'applet_market',
        meta: {
            pathTitle: 'applet_market',
            title: '营销列表'
        },
        component: () => import('@/views/marketing/marketingList.vue')
    }
]
export default marketing
