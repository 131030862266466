// 权限管理路由
const activity: any = [
    {
        path: 'activity/activity',
        name: 'applet_activity',
        meta: {
            title: '活动列表'
        },
        component: () => import('@/views/activity/activity.vue')
    },
    {
        path: 'activity/activityAdd',
        name: 'activityAdd',
        meta: {
            pathTitle: 'applet_activity',
            title: '新建活动'
        },
        component: () => import('@/views/activity/activityAdd.vue')
    },
    {
        path: 'activity/activityEdit',
        name: 'activityEdit',
        meta: {
            pathTitle: 'applet_activity',
            title: '编辑活动'
        },
        component: () => import('@/views/activity/activityEdit.vue')
    }
]
export default activity
