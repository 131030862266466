import { Module } from "vuex";
import { UserState } from "./types";
import { RootState } from "../../types";
import { getters } from "./getters";
import { actions } from "./actions";
import { mutations } from "./mutations";

const state: UserState = {
  user: "",
  token: "",
  sys: [],
  sysIndex: 0 // 默认第一个
};
const namespaced = true;
export const user: Module<UserState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
export default state;
