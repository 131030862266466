const general: any = [
    {
        path: 'file/file',
        name: 'apple_file_api',
        meta: {
            title: '订单列表'
        },
        component: () => import('@/views/file/file.vue')
    }
]
export default general
