// 权限管理路由
const integral: any = [
    {
        path: 'invite/list',
        name: 'applet_user_invite',
        meta: {
            title: '邀请记录'
        },
        component: () => import('@/views/invite/list.vue')
    },
    {
        path: 'tinymce/list',
        name: 'applet_information',
        meta: {
            title: '资讯列表'
        },
        component: () => import('@/views/tinymce/list.vue')
    },
    {
        path: 'tinymce/tinymce',
        name: 'tinymceAdd',
        meta: {
            title: '新建资讯'
        },
        component: () => import('@/views/tinymce/tinymce.vue')
    }
]
export default integral
