import { MutationTree } from "vuex";
import { SystemState, Item } from "./types";

export const mutations: MutationTree<SystemState> = {
    // 保存列
    saveFilter(state, column: Item) {
        for (const [i, v] of state.column.entries()) {
            if (v.name === column.name) {
                state.column.splice(i, 1)
                break
            }
        }
        state.column.push(
            Object.assign({}, column)
        )
    },
    // 保存菜单
    saveMenu(state, menu: Item) {
        for (const [i, v] of state.menu.entries()) {
            if (v.value === menu.value) {
                state.menu.splice(i, 1)
                break
            }
        }
        state.menu.push(
            Object.assign({}, menu)
        )
    },
    // 保存字典
    saveConfig(state, config: any) {
        state = Object.assign(state, {
            config: config
        })
    }
};
