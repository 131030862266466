// 权限管理路由
const integral: any = [
    {
        path: 'integral/commodity',
        name: 'applet_goods',
        meta: {
            title: '商品管理'
        },
        component: () => import('@/views/integral/commodity.vue')
    },
    {
        path: 'integral/pointsDetails',
        name: 'applet_integral_detail',
        meta: {
            title: '积分明细'
        },
        component: () => import('@/views/integral/pointsDetails.vue')
    },
    {
        path: 'integral/withdrawal',
        name: 'applet_integral_withdraw',
        meta: {
            title: '积分提现'
        },
        component: () => import('@/views/integral/withdrawal.vue')
    },
    {
        path: 'integral/withdrawalApproval',
        name: 'applet_integral_apply',
        meta: {
            title: '提现审批'
        },
        component: () => import('@/views/integral/withdrawalApproval.vue')
    },
    {
        path: 'integral/approalCard',
        name: 'approal',
        meta: {
            title: '提现审批',
            pathTitle: 'applet_integral_apply'
        },
        component: () => import('@/views/integral/approalCard.vue')
    }
]
export default integral
