// 权限管理路由
const member: any = [
    {
        path: 'member/list',
        name: 'applet_user_member',
        meta: {
            title: '会员列表'
        },
        component: () => import('@/views/member/list.vue')
    },
    {
        path: 'member/equitySetting',
        name: 'applet_user_befit',
        meta: {
            title: '权益设置'
        },
        component: () => import('@/views/member/equitySetting.vue')
    },
    /*{
        path: 'member/invitationRecord',
        name: 'invitationRecord',
        meta: {
            title: '邀请记录'
        },
        component: () => import('@/views/member/invitationRecord.vue')
    }*/
]
export default member
